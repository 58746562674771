import React, { useRef, useEffect } from 'react';
import TopBar from 'components/navigation/TopBar';
import Footer from 'components/navigation/Footer';
import Loading from 'components/modals/Loading';
import { appSettings } from 'config/app';
import { Toaster } from 'react-hot-toast';
import { WebSocket } from 'helpers/socket';
import { timer } from 'config/timer';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Horizontal = ({ children }) => {
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const timeoutRef = useRef(null);


  const { darkMode } = useSelector((state) => state.app);

  console.log('darkMode', darkMode);

  useEffect(() => {
    const resetTimer = () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      const config = timer[pathname];
      if (config?.enable) {
        timeoutRef.current = setTimeout(() => {
          navigate('/home');
        }, config.duration * 1000);
      }
    };

    const handleUserInteraction = () => resetTimer();
    document.addEventListener('click', handleUserInteraction);

    resetTimer();

    return () => {
      document.removeEventListener('click', handleUserInteraction);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [pathname, navigate]);

  return (
    <div className="flex flex-col items-center w-screen h-screen  text-3xl font-light text-gray-700 dark:text-white relative">
      <div
        className="absolute top-0 left-0 w-full h-full bg-cover bg-no-repeat bg-center -z-10"
        style={{
          backgroundImage: darkMode ?  'url(assets/bg-dark.svg)' : 'url(assets/bg-light.svg)',
        }}
      />
      <Loading />
      <Toaster
        position="top-center"
        containerClassName="text-2xl"
        toastOptions={appSettings.toast}
      />
      {appSettings.socket.active &&
        <div className="absolute top-5 start-5">
          <WebSocket showIconStatus />
        </div>
      }
      <div className="w-full pt-10">
        <TopBar />
      </div>
      <div className="w-full h-[75%] [&>*]:h-full [&>*]:w-full px-36">
        {children}
      </div>
      <div className="w-full h-[10%] pb-6">
        <Footer />
      </div>
    </div>
  )
}

export default Horizontal;