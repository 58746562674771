import axios from 'axios';

const axiosConfig = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

const AxiosInterceptor = ({ children }) => {

  const screenPaths = [
    'api/delivery/pin/verify',
    'api/delivery/pin/pickUp',
    'screen01/admin/pin/check',
  ]

  axiosConfig.interceptors.request.use(
    (config) => {

      const token = window.localStorage.getItem('token');

      const isScreenPath = screenPaths.some(prefix => config.url.startsWith(prefix));

      if (token && !isScreenPath) {
        config.headers['Authorization'] = `Bearer ${token}`;
      } else {
        config.headers['Authorization'] = `Bearer ${process.env.REACT_APP_API_TOKEN}`;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  axiosConfig.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error?.response?.status === 403) {
        window.location.href = "/403";
      }
      if (error?.response?.data?.code === 401) {
        if (error?.response?.data?.message === "Invalid JWT Token" || error?.response?.data?.message === "Expired JWT Token" || error?.response?.data?.message === "JWT Token not found") {
          //window.location.href = "/home";
        }
      }
      else {
        return Promise.reject(error);
      }
    }
  );

  return children;
}

export default axiosConfig;
export { AxiosInterceptor };
