import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { updateDarkMode, updateLanguage } from 'store/apps/app';
import { appSettings } from 'config/app';
import LanguageButton from 'components/common/LanguageButton';
import { Icon } from '@iconify/react';
import cn from 'classnames';
import toast from 'react-hot-toast';

const TopBar = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { language, darkMode } = useSelector((state) => state.app);

  const { selected: place } = useSelector(state => state.place);

  const { pathname } = useLocation();

  const { i18n } = useTranslation();

  const changeLanguage = (lang) => {
    dispatch(updateLanguage(lang));
    i18n.changeLanguage(lang);
  }

  const toggleTheme = () => {
    const root = window.document.documentElement

    if (root.classList.contains('dark')) {
      root.classList.remove('dark')
      root.classList.add('light')
      dispatch(updateDarkMode(false))
    } else {
      root.classList.remove('light')
      root.classList.add('dark')
      dispatch(updateDarkMode(true))
    }
  }

  useEffect(() => {
    window.document.documentElement.classList.add('light')
    dispatch(updateDarkMode(false))
    i18n.changeLanguage('pt');
    // eslint-disable-next-line
  }, [])


  useEffect(() => {
    if (!place) {
      toast.error(t('messages.error.no-location-assigned'));
      navigate('/home');
    }
    // eslint-disable-next-line
  }, [place, pathname])

  return (
    <div className="grid grid-cols-12 px-36 gap-x-10">
      <div className="col-span-5 flex justify-center w-full rounded-lg overflow-hidden">
        <img
          className="w-3/4"
          src={window.document.documentElement.classList.contains('dark') ? appSettings.logo.urlWhite : appSettings.logo.url}
          alt={appSettings.logo.alt}
          onClick={() => navigate('/home')}
        />
      </div>
      <div className="col-span-6 flex justify-end items-center w-full gap-x-5">
        <LanguageButton outlined={language !== 'pt'} onClick={() => changeLanguage('pt')}>
          PT
        </LanguageButton>
        <LanguageButton outlined={language !== 'en'} onClick={() => changeLanguage('en')}>
          EN
        </LanguageButton>
        <div onClick={toggleTheme} className="flex justify-between items-center font-normal text-3xl h-16 w-32 rounded-full border-2 border-primary text-primary px-5 relative overflow-hidden">
          <div
            className={cn(
              'h-20 w-16 bg-gradient-to-r from-lightBlue to-darkBlue absolute',
              {
                'top-0 bottom-0 -right-0.5': !darkMode,
                'top-0 bottom-0 -left-0.5': darkMode
              }
            )}
          />
          <Icon
            icon={darkMode ? 'clarity:moon-solid' : 'clarity:moon-line'}
            className={cn(
              'z-10',
              {
                'text-primary dark:text-white': darkMode,
                'text-primary': !darkMode,
              }
            )}
          />
          <Icon
            icon={darkMode ? 'clarity:sun-line' : 'clarity:sun-solid'}
            className={cn(
              'z-10',
              {
                'text-primary dark:text-white': darkMode,
                'text-white': !darkMode,
              }
            )}
          />
        </div>
      </div>
      <div className="col-span-1 flex justify-end items-center">
        {pathname !== '/home'
          ?
          <div className="border-2 border-primary text-3xl h-16 w-16 rounded-full font-normal flex justify-center items-center" onClick={() => navigate('/home')}>
            <Icon icon="lucide:home" className="text-primary dark:text-white" />
          </div>
          :
          <div className="border-2 border-primary text-3xl h-16 w-16 rounded-full font-normal flex justify-center items-center" onClick={() => navigate('/access')}>
            <Icon icon="lucide:user-round" className="text-primary dark:text-white" />
          </div>
        }
      </div>
    </div>
  )
}

export default TopBar;