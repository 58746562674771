import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { updateLoading } from '../app';
import toast from 'react-hot-toast';
import { t } from 'i18next';
import api from 'helpers/api'

export const checkAdmin = createAsyncThunk('admin/pin/check', async (params, { dispatch }) => {
  //dispatch(handleUpdateLoading(true));

  const config = { headers: { "Content-Type": "multipart/form-data" } };

  try {
    const response = await api.post('screen01/admin/pin/check', params, config)
    dispatch(updateLoading(false));

    if (!response.data.Result) {
      toast.error(t(`errors.${response?.data?.Code}`));
    }

    window.localStorage.setItem('token', response.data.Data.token)

    return response?.data;

  } catch (error) {
    dispatch(updateLoading(false));
    throw error;
  }
})

export const clearPIN = createAsyncThunk('admin/clear/pin', async (params, { dispatch }) => {
  window.localStorage.removeItem('token');
  return false
})


export const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    checked: false,
    logged: false,
  },
  reducers: {
    updateChecked: (state, action) => {
      state.checked = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(checkAdmin.fulfilled, (state, action) => {
      state.logged = action.payload?.Data?.details
    });
  }
})

export const { updateChecked } = adminSlice.actions;

export default adminSlice.reducer;
