export const timer = {
  '/home': {
    enable: false,
    duration: 10
  },
  '/access': {
    enable: true,
    duration: 5
  },
  '/select': {
    enable: false,
    duration: 20
  },
  '/deposit': {
    enable: false,
    duration: 20
  },
  '/success': {
    enable: true,
    duration: 10
  }
}